<script setup>
import { onMounted } from 'vue';
import { useUserStore } from '~/stores/user.store';
import { useListsStore } from '~/stores/account/lists.store';
import AddMobilePhone from './partials/add-mobile-phone.vue';

const userStore = useUserStore();
const listsStore = useListsStore();
const mobiles = computed(() => userStore.mobilesGetter());
const countries = computed(() => listsStore.countriesGetter());
const country = computed(() => countries.value?.find(c => c.id == mobiles.value?.mobile?.country_id));

const show_mobile_in_listings = ref(undefined);

watch(show_mobile_in_listings, async (newValue, oldValue) => {
  if (oldValue !== undefined) {
    await userStore.updateMobile({ show_mobile_in_listings: show_mobile_in_listings.value });
  }
});

const deleteMobilePhone = async () => {
  const res = await userStore.deleteMobile();
  if (res.status !== 200) return;
  await userStore.getMobiles();
}

onMounted(async () => {
  await userStore.getMobiles();
  await listsStore.fetchCountries({ has_listings: true });
  show_mobile_in_listings.value = mobiles.value?.mobile?.show_mobile_in_listings;
});
</script>
<template>
  <NuxtLayout name="mobile-base">
    <template #mobile-header>
      <mobile-header :title="$t('client.mobile_phones')">
        <template #header-left>
          <mobile-back-button></mobile-back-button>
        </template>
      </mobile-header>
    </template>
    <div class="mt-3 px-4" v-if="mobiles?.mobile?.mobile">
      <div class="flex items-center mt-3">
        <i class="fa fa-mobile-screen-button fa-xl me-3"></i>
        <i :class="`fi fi-${country?.code}`"></i>&nbsp; ({{ country?.phone_code }}) {{ mobiles.mobile.mobile }}
      </div>
      <div class="mt-3">
        <h3 class="text-base text-indigo-900 leading-normal mb-1">{{ $t('client.show_mobile_in_listings') }}</h3>
        <WToggle v-model:value="show_mobile_in_listings" passiveText="No" activeText="Yes" />
      </div>
      <div class="mt-6 flex items-center gap-3">
        <button @click="deleteMobilePhone"
          class="bg-red-600 text-white rounded-lg p-3 capitalize  w-full text-center">{{
            $t('client.delete') }}</button>
      </div>
    </div>
    <template v-else>
      <add-mobile-phone></add-mobile-phone>
    </template>

    <template #mobile-footer></template>
  </NuxtLayout>
</template>